import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme';
import moment from 'moment/moment'


function CreatedUpdatedField(props) {

    const { label, date } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const formatedDate = moment(date).format('DD.MM.YYYY')

    return (
        <Box display='flex' alignItems='center' gap='10px'>
            <Typography variant='subtitle2' noWrap sx={{ color: colors.grey[200] }}>
                {label}
            </Typography>
            <Typography variant='subtitle1' noWrap>
                {formatedDate}
            </Typography>
        </Box>
    )
}

export default CreatedUpdatedField