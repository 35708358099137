import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    createUser,
    updateUser,
    resetActiveUser,
    closeLoadingErrorDialog,
    getActiveUser,
    getUserDialogState,
    getUserUploadState,
} from '../../store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import PasswordVisibility from '../../components/switches/PasswordVisibility';


// yup email check with current version is not enough, therefore:
/* eslint-disable */
const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const userShape = {
    first_name: yup
        .string()
        .required('form.error.field_required'),
    last_name: yup
        .string()
        .required('form.error.field_required'),
    email: yup
        .string()
        .email('form.error.email_invalid_format')
        .matches(emailRegex, 'form.error.email_invalid_format')
        .required('form.error.email_required'),
};

const addSchema = yup.object().shape({
    ...userShape, password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.password_required')
});

const editSchema = yup.object().shape({
    ...userShape, password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
});


function FormUser() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activeUser = useSelector(getActiveUser);
    const userDialogState = useSelector(getUserDialogState);
    const uploadState = useSelector(getUserUploadState);

    const [showPassword, setShowPassword] = useState({ password: false, cpassword: false });

    const gridTextFieldStyle = { height: '66px' };
    const gridCreatedUpdatedStyle = {};

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        if (userDialogState.mode === 'add') {
            dispatch(createUser(values))

        };
        if (userDialogState.mode === 'edit') {
            const formated_values = removeEmptyPassword(values)
            dispatch(updateUser(formated_values))

        };
    };

    const removeEmptyPassword = (values) => {
        if (values.password === '') {
            delete values.password
            return values
        } else {
            return values
        }
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (userDialogState.mode === 'add') {
            dispatch(resetActiveUser());
        };
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={userDialogState.mode === 'add' ? { ...activeUser, password: "" } : activeUser}
                validationSchema={userDialogState.mode === 'add' ? addSchema : editSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>
                        <Grid
                            container
                        >
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.first_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.first_name || ''}
                                    name='first_name'
                                    error={!!touched.first_name && !!errors.first_name}
                                    helperText={touched.first_name && <>{t(errors.first_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.last_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.last_name || ''}
                                    name='last_name'
                                    error={!!touched.last_name && !!errors.last_name}
                                    helperText={touched.last_name && <>{t(errors.last_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.email')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email || ''}
                                    name='email'
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && <>{t(errors.email)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type={showPassword.password ? 'text' : 'password'}
                                    size="small"
                                    label={t('label.password')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password || ''}
                                    name='password'
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && <>{t(errors.password)}</>}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <PasswordVisibility
                                                    showPassword={showPassword.password}
                                                    onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                                                />
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            {userDialogState.mode === 'edit' ?
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeUser.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeUser.updated}
                                        />
                                    </Grid>
                                </>
                                :
                                null
                            }
                        </Grid>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={userDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                            labelReset={userDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    )
}

export default FormUser