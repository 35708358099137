import { Button, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';


function TableActionDelete(props) {

    const { onClick } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Button
            onClick={onClick}
            sx={{
                backgroundColor: colors.primary[400],
                '&:hover': {
                    backgroundColor: colors.primary[400],
                    color: colors.redAccent[500]
                }
            }}
        >
            <DeleteForeverOutlinedIcon />
        </Button>
    )
}

export default TableActionDelete