import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialUser = {
  first_name: "",
  last_name: "",
  email: "",
  disabled: false,
  permissions: [],
};

const initialState = {
  users: [],
  activeUser: initialUser,
  userDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, detail: null },
  },
};

export const getAllUsers = createAsyncThunk(
  "users/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/users/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/users/create`, user);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/${user.user_id}`, user);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (user, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/users/${user.user_id}`);
      return user.user_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    /* --- User ------------------------------------------- */
    setActiveUser: (state, action) => {
      state.activeUser = state.users.find(
        (user) => user.user_id === action.payload.user_id
      );
    },
    resetActiveUser: (state) => {
      state.activeUser = initialUser;
    },
    /* --- User dialog -------------------------------------*/
    startAddingUser: (state) => {
      state.userDialog.mode = "add";
      state.userDialog.activeTab = "general";
      state.userDialog.isOpen = true;
    },
    startEditingUser: (state) => {
      state.userDialog.mode = "edit";
      state.userDialog.activeTab = "general";
      state.userDialog.isOpen = true;
    },
    closeUserDialog: (state) => {
      state.userDialog.isOpen = false;
      state.userDialog.activeTab = "general";
      state.userDialog.mode = null;
      state.activeUser = initialUser;
    },
    setUserActiveTab: (state, action) => {
      state.userDialog.activeTab = action.payload;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingUser: (state) => {
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
      state.deleting.status = "idle";
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    /* --- READ ------------------------------------------- */
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.detail = action.payload.response.data.detail;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createUser.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.activeUser = action.payload;
        state.users.push(action.payload);
        state.uploading.status = "succeeded";
        state.userDialog.mode = "edit";
      })
      .addCase(createUser.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.detail = action.payload.response.data.detail;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateUser.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.activeUser = action.payload;
        const user_index = state.users.findIndex(
          (user) => user.user_id === action.payload.user_id
        );
        state.users[user_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.detail = action.payload.response.data.detail;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteUser.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const user_index = state.users.findIndex(
          (user) => user.user_id === action.payload
        );
        state.users.splice(user_index, 1);
        state.activeUser = initialUser;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.detail = action.payload.response.data.detail;
      });
  },
});

/* --- Users ------------------------------------------------------------------- */
export const getActiveUser = (state) => state.users.activeUser;
export const selectAllUsers = (state) => state.users.users;

/* --- User Dialog ------------------------------------------------------------- */
export const getUserDialogState = (state) => state.users.userDialog;

/* --- User Functions ---------------------------------------------------------- */
export const getUsersDownloadState = (state) => state.users.downloading;
export const getUserUploadState = (state) => state.users.uploading;
export const getUserDeleteState = (state) => state.users.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveUser,
  resetActiveUser,
  startAddingUser,
  startEditingUser,
  closeUserDialog,
  setUserActiveTab,
  startDeletingUser,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = usersSlice.actions;

export default usersSlice.reducer;
