import { configureStore } from "@reduxjs/toolkit";
import actionsMenuReducer from "./slices/actionsMenuSlice";
//import accountsReducer from "./slices/accountsSlice";
import loginReducer from "./slices/loginSlice";
import snackbarReducer from "./slices/snackbarSlice";
import usersReducer from "./slices/usersSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload"],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
  reducer: {
    actionsMenu: actionsMenuReducer,
    //accounts: accountsReducer,
    login: loginReducer,
    snackbar: snackbarReducer,
    users: usersReducer,
  },
});
