import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedUser: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    logoutUser: (state) => {
      state.loggedUser = {};
    },
  },
});

export const getLoggedUser = (state) => state.login.loggedUser;

export const { setLoggedUser, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
