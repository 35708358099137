import React from 'react'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../../theme';

function TableHeader(props) {

	const { title, icon, children } = props

	const theme = useTheme();
	const colors = tokens(theme.palette.mode)

	return (
		<Box
			display='flex'
			flexDirection={{ xs: 'column', md: 'row' }}
			justifyContent='space-between'
			alignItems='center'
			pb='24px'
			mx={{ xs: 0, md: "50px" }}
			gap='10px'
		>
			<Box
				display='flex'
				gap='20px'
				sx={{ color: colors.assigned.tableHeader, fontSize: '20px' }}
			>
				{icon}
				{title}
			</Box>
			{children}
		</Box>
	)
}

export default TableHeader