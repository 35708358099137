import { useSelector, useDispatch } from 'react-redux';
import {
    deleteUser,
    startAddingUser,
    closeUserDialog,
    setUserActiveTab,
    closeDeletingDialog,
    getActiveUser,
    getUserDialogState,
    getUserDeleteState,
} from '../../store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { tokens } from '../../theme';
import { Box, Dialog, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermission from '../../hooks/usePermission';
import TableHeader from '../../components/table/TableHeader';
import GridUsers from './GridUsers';
import FormUser from './FormUser';
import ConfirmDeleteDialog from '../../components/dialogs/ConfirmDeleteDialog';
import DragablePaperComponent from '../../components/dialogs/DragablePaperComponent';
import AddToListButton from '../../components/buttons/AddToListButton';
import TabsDialogContainer from '../../components/dialogs/TabsDialogContainer';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PermissionsTab from './PermissionsTab';
import AddNextButtonTabHeader from '../../components/buttons/AddNextButtonTabHeader';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';


function PageUsers() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const writeAccess = usePermission(['users_write'])

    const activeUser = useSelector(getActiveUser);
    const userDialogState = useSelector(getUserDialogState);
    const deleteState = useSelector(getUserDeleteState);


    const changeTab = (e, clickedTab) => {
        dispatch(setUserActiveTab(clickedTab));
    };

    const handleAddNewClick = () => {
        dispatch(startAddingUser());
    };

    const handleCloseUserDialog = () => {
        dispatch(closeUserDialog());
    };

    const handleDelete = () => {
        dispatch(deleteUser(activeUser));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.users')}
                    icon={<PeopleOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    <AddToListButton
                        text={t('button.add_new_user')}
                        onClick={handleAddNewClick}
                        permission={writeAccess}
                    />
                </TableHeader>
                <GridUsers />
            </Box>
            <SnackbarBasic />

            {/* DIALOG */}
            <Dialog
                open={userDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        width: isAboveMobileSize ? { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '600px' } : '95vw',
                        height: isAboveMobileSize ? '502px' : '502px',
                        m: "0px"
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={userDialogState.activeTab} >
                        <TabsDialogContainer
                            onClose={handleCloseUserDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <TabList
                                    onChange={changeTab}
                                    textColor='secondary'
                                    indicatorColor='secondary'
                                >
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.general') : null}
                                        icon={<DescriptionOutlinedIcon />}
                                        iconPosition="start"
                                        value="general"
                                        wrapped
                                    />
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.permissions') : null}
                                        icon={<KeyOutlinedIcon />}
                                        iconPosition="start"
                                        value="permissions"
                                        wrapped
                                        disabled={userDialogState.mode === 'add'}
                                    />
                                </TabList>
                                <AddNextButtonTabHeader
                                    text={'Add Next'}
                                    onClick={handleAddNewClick}
                                />
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height='435px'
                            position='relative' // absolute position of the button group in tabs
                        >
                            <TabPanel value="general">
                                <FormUser />
                            </TabPanel>
                            <TabPanel value="permissions">
                                <PermissionsTab />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DELETE DIALOG */}
            <ConfirmDeleteDialog
                open={deleteState.dialog}
                onDelete={handleDelete}
                onCancel={handleCloseDeletingDialog}
                status={deleteState.status}
                error={deleteState.error}
            />
        </>
    );
};

export default PageUsers;