import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { close, getSnackbarMessage, getSnackbarType, getSnackbarState } from '../../store/slices/snackbarSlice';
import { Alert, Snackbar } from '@mui/material';



function SnackbarBasic() {

    const dispatch = useDispatch()

    const state = useSelector(getSnackbarState)
    const message = useSelector(getSnackbarMessage)
    const type = useSelector(getSnackbarType)
    

    return (
        <Snackbar
            open={state}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => { dispatch(close()) }}
        >
            <Alert severity={type}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarBasic