import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const PublicRoute = (props) => {
    
    const { children } = props;
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate(`/${JSON.parse(window.localStorage.getItem('SIDEBAR_SELECTED_OPTION'))}` || '/dashboard', { replace: true, state: { from: location } });
        } else {
            setIsVerified(true);
        };
    }, [auth.isAuthenticated, location, navigate])


    if (!isVerified) {
        return null;
    };
    return (
        <>{children}</>
    );
}

export default PublicRoute