import { Box, Button, Menu, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';


function TableActionsMenu(props) {

    const { onClick, permission, children, anchorEl, setAnchorEl } = props

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const open = Boolean(anchorEl);


    return (
        <Box>
            <Button
                variant='outlined'
                color='secondary'
                onClick={onClick}
                disabled={!permission}
            >
                <MoreVertIcon />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiList-root': {
                        backgroundColor: colors.primary[500],
                        padding: '0'
                    },
                }}
            >
                <Box display='flex' flexDirection='row-reverse' gap='4px' p='4px'>
                    {children}
                </Box>
            </Menu>
        </Box>
    )
}

export default TableActionsMenu