import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';


function AdminChip() {

    const { t } = useTranslation();

    return (
        <Chip 
            icon={<StarBorderOutlinedIcon />} 
            label={t('chip.admin')} 
            variant="outlined"
            color="info"
            size="medium"
        />
    )
}

export default AdminChip