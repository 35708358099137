import { Box } from "@mui/material"
//import TileTotalAccounts from "./TileTotalAccounts"

function PageDashboard() {
    return (
        <Box
            sx={{
                display: { xs: 'flex', md: 'grid' },
                gridTemplateColumns: 'repeat(3,1fr)',
                gridAutoRows: 'mimmax(100px, auto)',
                gap: 3,
                textAlign: 'center',
                flexDirection: 'column'
            }}
        >
            {/* <TileTotalAccounts /> */}
        </Box>
    )
}

export default PageDashboard