import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { default as Auth } from "./components/auth/Authenticated";
import { default as Pub } from "./components/auth/PublicRoute";
import PageLogin from "./pages/login/PageLogin";
//import PageAccounts from "./pages/accounts/PageAccounts";
import PageDashboard from "./pages/dashboard/PageDashboard"
import PageUsers from "./pages/users/PageUsers";
import SideNav from "./components/bars/SideNav";
import Navbar from "./components/bars/Navbar";
import Page404 from "./pages/static/Page404";
import CircularProgressBasic from "./components/progress/CircularProgressBasic";


function App() {
  const [theme, colorMode] = useMode();

  return (
    <Suspense fallback={<CircularProgressBasic />}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <main className="content">
              <Routes>
                <Route element={<SideNav />}>
                  <Route element={<Navbar />}>
                    <Route path="/accounts" exact element={<Auth><PageUsers /></Auth>} />
                    <Route path="/dashboard" exact element={<Auth><PageDashboard /></Auth>} />
                    <Route path="/users" exact element={<Auth><PageUsers /></Auth>} />
                  </Route>
                </Route>
                <Route path="/" exact element={<Navigate to="/dashboard" replace />} />
                <Route path="*" exact element={<Navigate to="/404" replace />} />
                <Route path="/login" exact element={<Pub><PageLogin /></Pub>} />
                <Route path="/404" exact element={<Page404 />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Suspense>
  );
}

export default App;
