import { useSelector } from 'react-redux'
import { getLoggedUser } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import { Avatar, Box, Button, Typography } from '@mui/material'

function ActiveUser() {

    const { t } = useTranslation();
    const { logout } = useAuth();
    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY)

    const loggedUser = useSelector(getLoggedUser);

    return (
        <>
            {isAboveMediumScreens ? (
                <Box display='flex' alignItems='center' gap='16px'>
                    <Avatar src="/broken-image.jpg" />
                    <Box>
                        <Typography variant='h6'>
                            {loggedUser.first_name + " " + loggedUser.last_name}
                        </Typography>
                    </Box>
                    <Button
                        variant='outlined'
                        size='large'
                        color='secondary'
                        onClick={logout}
                        sx={{ borderRadius: '50px' }}
                    >
                        {t('button.logout')}
                    </Button>
                </Box>
            ) : (
                <Box
                    display='flex' flexDirection='column'
                    alignItems='center'
                    gap='20px'>
                    <Avatar
                        src="/broken-image.jpg"
                        sx={{ width: 80, height: 80 }}
                    />
                    <Box>
                        <Typography
                            variant='h3'
                            textAlign='center'
                            mx='20px'
                        >
                            {loggedUser.first_name + " " + loggedUser.last_name}
                        </Typography>
                    </Box>
                    <Button
                        variant='contained'
                        size='large'
                        color='secondary'
                        onClick={logout}
                        sx={{ borderRadius: '50px', px: '40px', py: '20px' }}
                    >
                        {t('button.logout')}
                    </Button>
                </Box>
            )}
        </>
    )
}

export default ActiveUser