import React from "react";
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FormLogin from "./FormLogin";
import LanguageSwitch from "../../components/switches/LanguageSwitch";
import backgroundImage from "../../assets/background/login_page.png"


const PageLogin = () => {

    const { t } = useTranslation()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Box
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ 
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPositionX: '50%'
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p="30px"
                borderRadius="6px"
                sx={{ backgroundColor: colors.primary[400] }}
            >
                <Typography variant='h3'>
                    {t('header.login')}
                </Typography>
                <FormLogin />
                <Box mt='20px' >
                    <LanguageSwitch />
                </Box>
            </Box>
        </Box>
    );
};

export default PageLogin;
