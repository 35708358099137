import settings from '../../config.json';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogTitle, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '../../hooks/useMediaQuery';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


function ErrorLoadingDataDialog(props) {

    const { open, error, onClose } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: colors.primary[500],
                    width: isAboveMobileSize ? { sm: '400px' } : '90vw',
                    maxWidth: '400px'
                }
            }}
        >
            <DialogTitle>
                {t('header.error')}
            </DialogTitle>
            <Box display='flex' justifyContent='center' alignItems='center' pb='20px'>
                <Box width='40%' display='flex' justifyContent='center' mr='-30px'>
                    <ErrorOutlineIcon sx={{ color: colors.redAccent[500], fontSize: '100px' }} />
                </Box>
                <Box width='60%' display='flex' justifyContent='center' mr='20px'>
                    {error.detail}
                </Box>
            </Box>

            <DialogActions sx={{ borderTop: 1, borderColor: 'divider' }}>
                <Box
                    width='100%'
                    display='flex'
                    justifyContent={isAboveMobileSize ? 'flex-end' : 'center'}
                >
                    <Button
                        type='submit'
                        variant="contained"
                        onClick={onClose}
                    >
                        {t('button.close')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorLoadingDataDialog