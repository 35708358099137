import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import settings from '../config.json'
import useMediaQuery from '..//hooks/useMediaQuery';


export default function DataGridStyle(props) {

    const { children } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY)


    return (
        <Box
            width='100%'
            sx={{
                '& .MuiDataGrid-root': {
                    minHeight: '300px',
                    maxHeight: isAboveMediumScreens ? '750px' : null,
                    border: 'none',
                },
                '& .MuiDataGrid-cell': {
                    //borderBottom: 'none'
                },
                '& .name-column--cell': {
                    //color: colors.greenAccent[300]
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: colors.primary[400],
                    borderBottom: 'none'
                },
                '& .MuiDataGrid-virtualScroller': {
                    backgroundColor: colors.primary[500],
                    overflow: isAboveMediumScreens ? 'auto' : null,
                },
                '& .MuiDataGrid-footerContainer': {
                    backgroundColor: colors.primary[400],
                    borderTop: 'none',
                    borderBottom: 'none',
                },
                '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                    color: `${colors.grey[100]} !important`
                },
                // No outline for active cells
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                    outline: "none !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
                // Loading ProgressBar
                '& .MuiLinearProgress-root': {
                    backgroundColor: 'transparent',
                },
                '& .css-1hs498w-MuiLinearProgress-bar1': {
                    backgroundColor: colors.greenAccent[400],
                },
                '& .css-1qg49kn-MuiLinearProgress-bar2': {
                    backgroundColor: colors.greenAccent[600],
                },
            }}
        >
            {children}
        </Box>
    )
}
