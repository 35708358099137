import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAllUsers,
    setActiveUser,
    startEditingUser,
    startDeletingUser,
    selectAllUsers,
    getUsersDownloadState,
} from '../../store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../hooks/usePermission';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../styles/DataGridStyle';
import TableActionsMenu from '../../components/table/TableActionsMenu';
import TableActionEdit from '../../components/table/TableActionEdit';
import TableActionDelete from '../../components/table/TableActionDelete';
import AdminChip from '../../components/chips/AdminChip';
import ActiveChip from '../../components/chips/ActiveChip';
import DisabledChip from '../../components/chips/DisabledChip';
import NoRowsOverlay from '../../assets/overlays/NoRowsOverlay';
import NoPermissionsOverlay from '../../assets/overlays/NoPermissionsOverlay';
import moment from 'moment/moment'


function ProductsPage() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);

    const writeAccess = usePermission(['users_write'])
    const readAccess = usePermission(['users_read'])

    const rows = useSelector(selectAllUsers);
    const downloadState = useSelector(getUsersDownloadState);

    const [anchorEl, setAnchorEl] = useState(null);


    const columns = [
        {
            field: 'user_id',
            headerName: '#',
            width: 40,
            sortable: false,
            filterable: false,
            hideable: false,
            columnMenu: false,
            disableColumnMenu: true,
            valueGetter: (params) => params.api.getRowIndexRelativeToVisibleRows(params.row.user_id) + 1,
        },
        {
            field: 'first_name',
            headerName: t('label.first_name'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'last_name',
            headerName: t('label.last_name'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'email',
            headerName: t('label.email'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'function',
            headerName: t('label.function'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                const array = params.row.permissions;
                if (array.includes('admin')) {
                    return t('chip.admin');
                };
            },
            renderCell: (params) => {
                const array = params.row.permissions;
                if (array.includes('admin')) {
                    return <AdminChip />;
                };
            },
        },
        {
            field: 'disabled',
            headerName: t('label.status'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                if (params.row.disabled === true) {
                    return t('chip.disabled');
                } else {
                    return t('chip.active');
                };
            },
            renderCell: (params) => {
                if (params.row.disabled === true) {
                    return <DisabledChip />;
                } else {
                    return <ActiveChip />;
                };
            },
        },
        {
            field: 'created',
            headerName: t('label.created'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment(params.row.created).format('DD.MM.YYYY')
        },
        {
            field: 'actions',
            headerName: t('label.actions'),
            minWidth: 100,
            sortable: false,
            filterable: false,
            hideable: false,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            renderCell: () => (
                <TableActionsMenu
                    onClick={handleMenuClick}
                    permission={writeAccess}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}

                >
                    <TableActionEdit onClick={handleEditClick} />
                    <TableActionDelete onClick={handleDeleteClick} />
                </TableActionsMenu>
            )
        },
    ];

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(getAllUsers());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const handleRowClick = (params) => {
        dispatch(setActiveUser(params.row));
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleEditClick = () => {
        dispatch(startEditingUser());
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        dispatch(startDeletingUser());
        setAnchorEl(null);
    };


    return (
        <DataGridStyle>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.user_id}
                loading={downloadState.status === 'loading'}
                onRowClick={(params) => handleRowClick(params)}
                initialState={{
                    sorting: { sortModel: [{ field: 'last_name', sort: 'asc' }] },
                    pagination: { paginationModel: { pageSize: 100 } },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterExcludeHiddenColumns: true,
                        },
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: readAccess ? NoRowsOverlay : NoPermissionsOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                sx={{ '--DataGrid-overlayHeight': '300px' }}
            />
        </DataGridStyle>
    );
};

export default ProductsPage;