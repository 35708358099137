import { Button, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


function TableActionEdit(props) {

    const { onClick } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Button
            onClick={onClick}
            sx={{
                backgroundColor: colors.primary[400],
                '&:hover': {
                    backgroundColor: colors.primary[400],
                    color: colors.blueAccent[500]
                }
            }}
        >
            <EditOutlinedIcon />
        </Button>
    )
}

export default TableActionEdit