import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


function ActiveChip() {

    const { t } = useTranslation();

    return (
        <Chip 
            icon={<CheckOutlinedIcon />} 
            label={t('chip.active')} 
            variant="outlined"
            color="success"
            size="medium"
        />
    )
}

export default ActiveChip