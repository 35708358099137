import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';


function DisabledChip() {

    const { t } = useTranslation();

    return (
        <Chip 
            icon={<BlockOutlinedIcon />} 
            label={t('chip.disabled')} 
            variant="outlined"
            color="error"
            size="medium"
        />
    )
}

export default DisabledChip