import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateUser,
    getActiveUser,
    closeLoadingErrorDialog,
    getUserUploadState,
} from '../../store/slices/usersSlice';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Grid, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { Formik, Form } from 'formik';
import usePermission from '../../hooks/usePermission';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import DividerLeftLabel from '../../components/dialogs/DividerLeftLabel';


const initialCheckboxState = {
    admin: false,
    users_read: false,
    users_write: false
};


function PermissionsTab() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const adminAccess = usePermission([]);

    const activeUser = useSelector(getActiveUser);
    const uploadState = useSelector(getUserUploadState);

    const [permissionsArray, setPermissionsArray] = useState(activeUser.permissions);
    const [checkboxState, setCheckboxState] = useState(initialCheckboxState);
    const [admin, setAdmin] = useState(false);

    const gridCheckBoxStyle = {};


    useEffect(() => {
        /* eslint-disable */
        updateCheckboxes();
        checkIfAdmin();

    }, [activeUser.permissions]);

    /* ------ SUBMIT ---------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        dispatch(updateUser(
            {
                user_id: values.user_id,
                disabled: values.disabled,
                permissions: permissionsArray,
            },
        ));
    };

    /* ------ PERMISSIONS ----------------------------------------------------------------*/

    const handlePermissionsClick = (e) => {

        // Handle Permissions array
        let updated_array = [...permissionsArray]; // copy to make extensible
        const { name, checked } = e.target;
        if (checked) {
            if (!updated_array.includes(String(name))) {
                updated_array.push(String(name));
            }
        } else {
            if (updated_array.includes(String(name))) {
                let index = updated_array.indexOf(String(name));
                updated_array.splice(index, 1);
            };
        };
        setPermissionsArray(updated_array);

        // Handle CheckBox state:    
        Object.keys(checkboxState).forEach(checkbox => {
            if (name === checkbox) {
                if (updated_array.includes(checkbox)) {
                    setCheckboxState({ ...checkboxState, [name]: true });
                } else {
                    setCheckboxState({ ...checkboxState, [name]: false });
                };
            };
        });
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    const handleResetClick = () => {

        setPermissionsArray(activeUser.permissions); // Reset array
        updateCheckboxes(); // Reset Checkboxes
    };

    /* --- CHECKBOXES STATE --------------------------------------------------------------*/

    const updateCheckboxes = () => {

        let original_array = [...activeUser.permissions];
        Object.keys(checkboxState).forEach(checkbox => {
            if (original_array.includes(checkbox)) {
                setCheckboxState(checkboxState => ({ ...checkboxState, [checkbox]: true }));
            } else {
                setCheckboxState(checkboxState => ({ ...checkboxState, [checkbox]: false }));
            };
        });
    };

    /* ------ IS ADMIN -------------------------------------------------------------------*/

    const checkIfAdmin = () => {

        let array = [...activeUser.permissions];
        if (array.includes('admin')) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeUser}
                enableReinitialize
            >
                {({ values, /*errors, touched, handleBlur,*/ handleChange, handleSubmit }) => (
                    <Form>
                        <DividerLeftLabel label='GLOBAL' />
                        <Grid
                            container
                        >
                            <Grid
                                item
                                xs={6} md={4}
                                sx={gridCheckBoxStyle}
                            >
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={t('label.admin')}
                                    checked={checkboxState.admin || false}
                                    onChange={handlePermissionsClick}
                                    name='admin'
                                    disabled={!adminAccess}
                                />
                            </Grid>
                            {!admin ? (
                                <Grid
                                    item
                                    xs={6} md={4}
                                    sx={gridCheckBoxStyle}
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="error"
                                            sx={{ color: colors.redAccent[400] }}
                                        />}
                                        label={t('label.disabled')}
                                        checked={Boolean(values.disabled) || false}
                                        onChange={handleChange}
                                        name='disabled'
                                        sx={{ color: colors.redAccent[400] }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>

                        {!admin ? (
                            <>
                                <DividerLeftLabel label='PAGES' />
                                <Grid
                                    container
                                >
                                    <Grid
                                        item
                                        xs={6} md={4}
                                        sx={gridCheckBoxStyle}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('label.users_read')}
                                            checked={checkboxState.users_read || false}
                                            onChange={handlePermissionsClick}
                                            name='users_read'
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6} md={4}
                                        sx={gridCheckBoxStyle}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('label.users_write')}
                                            checked={checkboxState.users_write || false}
                                            onChange={handlePermissionsClick}
                                            name='users_write'
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        ) : null}

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={t('button.edit')}
                            labelReset={t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    )
}

export default PermissionsTab